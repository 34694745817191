import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Urn from '../views/Urn.vue'
import NotFound from '../views/NotFound.vue'
import Mail from '../views/Mail.vue'
import CollectMail from '../views/CollectMail.vue'
//import container from '../views/container.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // Prod links
  {
    path: '/container',
    name: 'Container',
    component: Urn,
  },
  {
    path: '/room',
    name: 'Room',
    component: Urn,
  },
  {
    path: '/mailbox',
    name: 'Mailbox',
    component: Mail,
  },
  {
    path: '/collectMail',
    name: 'CollectMail',
    component: CollectMail,
  },
  // Localhost links
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router