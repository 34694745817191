<script setup>
import { onMounted, ref } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";

import SimpleDialog from "../components/SimpleDialog.vue";

const route = useRoute();

const uuid = ref(route.query.uuid);
const wrongUuid = ref(false);
const toCollect = ref([]);

const openDialog = ref(false);
const dialogString = ref("");

onMounted(async () => {
  toCollect.value = await axios
    .get(
      `https://poc-hso.rfid-solutions.no/poc-hso/collectMail?uuid=${uuid.value}&url=${window.location.origin}`,
      {}
    )
    .then((resp) => {
      const data = resp.data;

      for (let i = 0; i < data.length; i++) {
        try {
          data[i].displayName = decodeURIComponent(escape(data[i].displayName));
        } catch (e) {
          data[i].displayName = data[i].displayName.replaceAll("Ã¸", "ø");
          data[i].displayName = data[i].displayName.replaceAll("Ã˜", "Ø");
        }
      }

      wrongUuid.value = false;

      return data;
    })
    .catch(() => {
      wrongUuid.value = true;
      return [];
    });
});

async function collect() {
  const collectPayload = {
    uuid: uuid.value,
    url: window.location.origin,
  };
  
  await axios
    .post(
      "https://poc-hso.rfid-solutions.no/poc-hso/collectMail",
      collectPayload,
      {}
    )
    .then(() => {
      dialogString.value = "Registrering av henting er fullført!";
      openDialog.value = true;
    })
    .catch((error) => {
      openDialog.value = true;
      dialogString.value = error.message;
    });
}

function closeDialog() {
  openDialog.value = false;
  window.location.reload();
}
</script>

<template>
  <q-page class="collectmail-page-content">
    <div class="collectmail-card">
      <div class="collectmail-card-content">
        <div v-if="wrongUuid">Invalid UUID!</div>
        <div v-if="toCollect.length === 0">Alle postkasser er registert hentet</div>
        <table>
          <tr v-for="mailbox in toCollect" :key="mailbox.gln">
            <td>{{ mailbox.gln }}</td>
            <td>{{ mailbox.displayName }}</td>
          </tr>
        </table>
      </div>

      <div class="collectmail-card-action" v-if="!wrongUuid && toCollect.length > 0">
        <button @click="collect()">Registrer Henting</button>
      </div>
    </div>

    <SimpleDialog :open="openDialog" height="150px" width="200px">
      <template v-slot:header>
        Info
      </template>
      {{ dialogString }}
      <template v-slot:action>
        <button @click="closeDialog()">
          Lukk
        </button>
      </template>
    </SimpleDialog>
  </q-page>
</template>

<style scoped>
.collectmail-page-content {
  height: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.collectmail-card {
  background-color: #1976d2;
  height: 100%;
  width: 100%;
  color: white;
  border-radius: 3px;
}

.collectmail-card-content {
  padding: 10px;
  height: 100%;
  overflow-y: auto;
}

.collectmail-card table {
  border-style: hidden;
  border-collapse: collapse;
}

.collectmail-card table td,
table th {
  border: 1px solid white;
  padding: 3px 10px 3px 10px;
}

.collectmail-card-action {
  width: 100%;
  height: 100%;
  border-top: 1px solid lightblue;
  padding: 5px;
}

.collectmail-card-action button {
  width: 100%;
  height: 70px;
  background-color: white;
  border: none;
  border-radius: 4px;
  font-size: large;
  text-transform: uppercase;
}

.collectmail-card button:hover {
  cursor: pointer;
}

.collectmail-card button:active {
  filter: brightness(80%);
}
</style>
