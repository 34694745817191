<script setup>
  import { useRoute} from 'vue-router'
  import { onMounted, ref, computed } from 'vue'
  import axios from 'axios'

  const route = useRoute()

  const urn = ref(route.query.urn)

  const pageState = ref(0)
  const mailboxState = ref(0)

  const room = ref("")
  const mailbox = ref("")
  const ordered = ref("")

  const verifyDisabled = ref(false)
  const mailboxDisabled = ref(false)
  const mailboxIsDisabled = computed(() => mailboxDisabled.value);

  const verifyIsDisabled = computed(() => {
    if (mailboxDisabled.value === true) {
      return 'lime';
    } else {
      return 'white';
    }
  });

  const handleButton = async() => {
    await axios.post('https://poc-hso.rfid-solutions.no/poc-hso/orderCollectMail', {"urn": urn.value, "url": window.location.origin}, {})
      .then((response) => {
        if (response.status === 200) {
          mailboxDisabled.value = true
          ordered.value = new Date(Date.now()).toLocaleDateString("nb-NO") + " " + new Date(Date.now()).toLocaleTimeString("nb-NO")
          verifyDisabled.value = true
          mailboxState.value = 2
        }

      }).catch((err) => {
        console.log(err)
      })
  }

  const getmailboxTelemetry = async() => {
    await axios.get('https://poc-hso.rfid-solutions.no/poc-hso/getContainer?urn=' + urn.value + "&url=" + window.location.origin, {})
    .then((response) => {
      pageState.value = 1

      if(response.data.length !== 0) {
        if (/[ÆØÅæøå]/g.test(response.data.roomName)) {
            room.value = response.data.roomName
        } else {
          let fixedstring;
            try {
                fixedstring = decodeURIComponent(escape(response.data.roomName)); 
            }catch(e) {
                response.data.roomName = response.data.roomName.replaceAll("Ã¸", "ø");
                response.data.roomName = response.data.roomName.replaceAll("Ã˜", "Ø");
                fixedstring = response.data.roomName;
            }
            
          room.value = fixedstring;
        }

        if (response.data.toEmpty === "true") {
          mailboxState.value = 2
          ordered.value = new Date(response.data.toEmptyTs).toLocaleDateString("nb-NO") + " " + new Date(response.data.toEmptyTs).toLocaleTimeString("nb-NO");
          mailboxDisabled.value = true
        } else {
          mailboxState.value = 1
        }
      } 
    }).catch((err) => {
      pageState.value = 0
      console.log(err)
    })
  }

  onMounted(async () => {
    mailbox.value = urn.value
    await getmailboxTelemetry()
  })
</script>

<template>
  <q-page>
    <div class=center-screen>
      <div class="q-pa-md row items-start">
        <!-- pageState 0 is for wrong url, error in server or not registered container in room-->
        <div v-if="pageState === 0">
        </div>

        <!-- pageState 1 is for mailbox info -->
        <div v-else-if="pageState === 1">
          <q-card class="bg-primary text-white no-margin full-height">
              <q-card-section>
                <!-- mailboxState 1 is for mailboxState information when not ordered tømming -->
                <div v-if="mailboxState === 1">
                  <div class="information-mailbox">
                    <div class="string-info">
                      <p>Rom:</p>
                      <p>Postkasse:</p>
                    </div>
                    <div class="val-info">
                      <p>{{ room }}</p>
                      <p>{{ mailbox }}</p>
                    </div>
                  </div>
                </div>

                <!-- mailboxState 2 is for mailboxState Information when ordered tømming -->
                <div v-else-if="mailboxState === 2">
                  <div class="information-mailbox">
                    <div class="string-info">
                      <p>Rom:</p>
                      <p>Postkasse:</p>
                      <p>Bestilt: </p>
                    </div>
                    <div class="val-info">
                      <p>{{ room }}</p>
                      <p>{{ mailbox }}</p>
                      <p>{{ ordered }}</p>
                    </div>
                  </div>
                </div>

            </q-card-section>

            <q-separator dark />

            <q-card-actions align="around">
              <q-btn :color="verifyIsDisabled" text-color="black" style ="width:100%; height: 70px; font-size: 18px;" :disabled="mailboxIsDisabled" @click="handleButton">{{ mailboxIsDisabled ? "HENTET BESTILT" : "BESTILL POSTHENTING" }}</q-btn>
            </q-card-actions>
          </q-card>
        </div>
      </div>
    </div>
  </q-page>
</template>

<style>

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 75vh;
}

.information-mailbox {
  display: flex;
  flex-wrap: nowrap;
}

.val-info, .string-info {
  flex: 1, 1, 100px;
  white-space: nowrap;
}

p, li
{
  font-size: 4vmin;
}
@media screen and (min-width: 1200px) {
  p, li {
    font-size: 25px;
  }  
}

#green {
  background: green;
}

</style>