<script setup>
  import { useRoute} from 'vue-router'
  import { onMounted , ref, computed, watch } from 'vue'
  import axios from 'axios'
  import { QrStream } from 'vue3-qr-reader'

  const maintenanceText = ref("")

  const route = useRoute()

  const containerState = ref(0)
  const pageState = ref(0)

  const urn = ref(route.query.urn)
  const site = ref(route.name)

  const room = ref("")
  const container = ref("")
  const last = ref("")
  const ordered = ref("")

  const verifyDisabled = ref(false)
  const containerDisabled = ref(false)
  const containerIsDisabled = computed(() => containerDisabled.value);
  const verifyIsDisabled = computed(() => {
    if (verifyDisabled.value === true) {
      return 'lime';
    } else {
      return 'white';
    }
  });

  const onDecode = async(data) => {
    let urlSplitter = data.split("/")

    if (urlSplitter[3] === "414") {
      if (urlSplitter[4].startsWith("7080006") || urlSplitter[4].startsWith("7080004")){
        await axios.post('https://poc-hso.rfid-solutions.no/poc-hso/linkContainer', {"containerUrn": urn.value, "roomUrn": urlSplitter[4], "url": window.location.origin}, {})
        .then((response) => {

          console.log(response)
          console.log(urn.value)
          // Refresh page
          window.location.reload()
        }).catch((err) => {
          console.log(err)
        })

      }
    }
  }

  const handleButton = async() => {
    await axios.post('https://poc-hso.rfid-solutions.no/poc-hso/handleButton', {"urn": urn.value, "url": window.location.origin}, {})
      .then((response) => {
        if (response.status === 200) {
          containerDisabled.value = true
          ordered.value = new Date(Date.now()).toLocaleDateString("nb-NO") + " " + new Date(Date.now()).toLocaleTimeString("nb-NO")
          verifyDisabled.value = true
          containerState.value = 2
        }

      }).catch((err) => {
        console.log(err)
      })
  }

  const getContainerInRoom = async() => {
      return await axios.get('https://poc-hso.rfid-solutions.no/poc-hso/getContainerInRoom?urn=' + urn.value + "&url=" + window.location.origin)
      .then((response) => {
        return response.data.containerId
      }).catch((err) => {
        console.log(err)
        pageState.value = 0
        return null
      })
  }

  const getContainerTelemetry = async() => {
      await axios.get('https://poc-hso.rfid-solutions.no/poc-hso/getContainer?urn=' + urn.value + "&url=" + window.location.origin, {})
      .then((response) => {

        if(response.data.length === 0) {
          
          pageState.value = 2

        } else {

          pageState.value = 1
          containerState.value = 1

          if(response.data.toEmpty === "true") {
            containerState.value = 2
            ordered.value = new Date(response.data.toEmptyTs).toLocaleDateString("nb-NO") + " " + new Date(response.data.toEmptyTs).toLocaleTimeString("nb-NO");
            containerDisabled.value = true

          }
          if (/[ÆØÅæøå]/g.test(response.data.roomName)) {
              room.value = response.data.roomName
          } else {
            let fixedstring;
              try {
                  fixedstring = decodeURIComponent(escape(response.data.roomName)); 
              }catch(e) {
                  response.data.roomName = response.data.roomName.replaceAll("Ã¸", "ø");
                  response.data.roomName = response.data.roomName.replaceAll("Ã˜", "Ø");
                  fixedstring = response.data.roomName;
              }
              
            room.value = fixedstring;
          }
          last.value = new Date(response.data.ts).toLocaleDateString("nb-NO") + " " + new Date(response.data.ts).toLocaleTimeString("nb-NO");
          container.value = response.data.container;
        }

      }).catch((err) => {

        console.log(err)
        pageState.value = 0
      
      })
  }

  const getMaintenanceStatus = async() => {
    let status = false

    await axios.get("https://poc-hso.rfid-solutions.no/poc-hso/getMaintenanceStatus?url=https://digitallink2.rfid-solutions.no", {})
    .then((response) => {
      status = response.data.status
      maintenanceText.value = response.data.text
    })
    .catch((err) => {
      console.log(err)
      pageState.value = 0
    })

    return status
  }

  watch(() => route.name, async () => {
    if (await getMaintenanceStatus() === true) {
      pageState.value = -1
      return
    }

    if (route.name === "Container") {
      await getContainerTelemetry()
    }

    if (route.name === "Room") {

      let containerRoom = await getContainerInRoom()

      if (containerRoom !== null) {
        urn.value = '0' + containerRoom
        site.value = "Container"
        await getContainerTelemetry()
      }

    }
  })

  onMounted(async () => {
    if (await getMaintenanceStatus() === true) {
      pageState.value = -1
      return
    }

    if (site.value === "Container") {
      await getContainerTelemetry()
    }

    if (site.value === "Room") {

      let containerRoom = await getContainerInRoom()

      if (containerRoom !== null) {
        urn.value = '0' + containerRoom
        site.value = "Container"
        await getContainerTelemetry()
      }

    }
  })



</script>

<template>
  <q-page>
    <div class=center-screen>
      <div class="q-pa-md row items-start">
        <!-- pageState -1 is for maintenance or update-->
        <div v-if="pageState === -1">
          <q-card class="bg-primary text-white no-margin full-height">
            <q-card-section>
              <div class="text-h6">{{ "Vi vedlikeholder/oppdaterer for tiden våre servere. Vennligst prøv igjen etter " + maintenanceText}} </div>
            </q-card-section>
          </q-card>
        </div>
        
        <!-- pageState 0 is for wrong url, error in server or not registered container in room-->
        <div v-if="pageState === 0">
        </div>

        <!-- pageState 1 is for containerInformation -->
        <div v-else-if="pageState === 1">
          <q-card class="bg-primary text-white no-margin full-height">
              <q-card-section>

                  <!-- containerState 1 is for containerinformation when not ordered tømming -->
                  <div v-if="containerState === 1">
                    <div class="information-container">
                    <div class="string-info">
                      <p>Rom:</p>
                      <p>Beholder:</p>
                      <p>Utplassert: </p>
                    </div>
                    <div class="val-info">
                      <p>{{ room }}</p>
                      <p>{{ container }}</p>
                      <p>{{ last }}</p>
                    </div>
                  </div>
                  </div>

                  <!-- containerState 2 is for containerInformation when ordered tømming -->
                  <div v-else-if="containerState === 2">
                    <div class="information-container">
                    <div class="string-info">
                      <p>Rom:</p>
                      <p>Beholder:</p>
                      <p>Utplassert: </p>
                      <p>Bestilt: </p>
                    </div>
                    <div class="val-info">
                      <p>{{ room }}</p>
                      <p>{{ container }}</p>
                      <p>{{ last }}</p>
                      <p>{{ ordered }}</p>
                    </div>
                  </div>
                  </div>

              </q-card-section>

              <q-separator dark />

              <q-card-actions align="around">
                <q-btn :color="verifyIsDisabled" text-color="black" style ="width:100%; height: 70px; font-size: 18px;" :disabled="containerIsDisabled" @click="handleButton">{{ containerIsDisabled ? "TØMMING BESTILT" : "BESTILL TØMMING" }}</q-btn>
              </q-card-actions>
            </q-card>
          </div>

        <!-- pageState 2 is for qr reader -->
        <div v-else-if="pageState === 2">
          <div class="stream">
            <qr-stream @decode="onDecode" class="mb">
              <div style="color: red;" class="frame"></div>
            </qr-stream>
          </div>
        </div>

      </div>
    </div>
  </q-page>
</template>

<style>

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 75vh;
}

.information-container {
  display: flex;
  flex-wrap: nowrap;
  height: 100% !important;
  width: 100% !important;
}

.val-info, .string-info {
  flex: 1, 1, 100px;
  white-space: nowrap;
}

p, li
{
  font-size: 4vmin;
}
@media screen and (min-width: 1200px) {
  p, li {
    font-size: 25px;
  }  
}

#green {
  background: green;
}

</style>