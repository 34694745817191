<template>
  <div class="notfound">
      <h1>Error: 404</h1>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'NotFound'
}
</script>